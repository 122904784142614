<template>
  <div class="dp-pro-recipe-taste-map">
    <div class="dp-pro-recipe-taste-map-axis">
      <span class="dp-pro-recipe-taste-map-axis__mild">マイルド</span>
      <span class="dp-pro-recipe-taste-map-axis__bitter">苦味</span>
      <span class="dp-pro-recipe-taste-map-axis__rich">コク</span>
      <span class="dp-pro-recipe-taste-map-axis__acidity">酸味</span>
    </div>

    <div class="dp-pro-recipe-taste-map-cells">
      <div
        v-for="(cells, i) in cellsArray"
        :key="`array${i}`"
        class="dp-pro-recipe-taste-map-cells__group">
        <div
          v-for="cell in cells"
          :key="cell"
          class="dp-pro-recipe-taste-map-cells__cell">
          <Icon
            v-if="capsulePosition === cell"
            name="diamondOff"
            color="dpBlack01"
            :width="19"
            :height="19" />
          <Icon
            v-if="recipePosition === cell"
            name="startOn"
            color="dpPrimary"
            :width="19"
            :height="19" />
        </div>
      </div>
      <div class="dp-pro-recipe-taste-map-cells__center">
        <Icon
          v-if="capsulePosition === 101"
          name="diamondOff"
          color="dpBlack01"
          :width="19"
          :height="19" />
        <Icon
          v-if="recipePosition === 101"
          name="startOn"
          color="dpPrimary"
          :width="19"
          :height="19" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    capsulePosition: {
      type: Number,
      default: null
    },

    recipePosition: {
      type: Number,
      default: null
    }
  },

  setup() {
    const cellsArray = computed(() => {
      const array = [...Array(100)].map((_, i) => i + 1);
      return sliceByNumber(array, 25);
    });

    const sliceByNumber = (array, number) => {
      const length = Math.ceil(array.length / number);

      return new Array(length)
        .fill()
        .map((_, i) => array.slice(i * number, (i + 1) * number));
    };

    return {
      cellsArray
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

$tasteMapCellSize: 25;
$horizontalCellCount: 10;

.dp-pro-recipe-taste-map {
  position: relative;
  margin: 0 auto 16px;
  padding: 26px 24px;
  width: $tasteMapCellSize * $horizontalCellCount + 2 + 24 * 2px; // 2 -> 中央線の太さ（セルのマージン）、24 -> 表の左右パディング
}

.dp-pro-recipe-taste-map-axis {
  color: variables.$dpBlack01;
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;

  &__mild,
  &__bitter,
  &__rich,
  &__acidity {
    position: absolute;
    text-align: center;
  }

  &__mild,
  &__rich {
    left: 50%;
    transform: translateX(-50%);
  }

  &__bitter,
  &__acidity {
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
  }

  &__mild {
    top: 0;
  }

  &__bitter {
    right: 0;
  }

  &__rich {
    bottom: 0;
  }

  &__acidity {
    left: 0;
  }
}

.dp-pro-recipe-taste-map-cells {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  width: $tasteMapCellSize * $horizontalCellCount + 2 + px; // 2 -> 中央線の太さ（セルのマージン）
  overflow: hidden;

  &__group {
    display: flex;
    flex-wrap: wrap;
    background-color: variables.$dpWhite01;
  }

  &__cell {
    display: grid;
    place-items: center;
    width: $tasteMapCellSize + px;
    height: $tasteMapCellSize + px;
  }
  &__center {
    display: grid;
    place-items: center;
    width: $tasteMapCellSize + px;
    height: $tasteMapCellSize + px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
